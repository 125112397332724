<template>
  <div class="flex flex-col">
    <input
      ref="inputRef"
      v-bind="$attrs"
      :name="name"
      type="text"
    >

    <span
      v-if="error"
      class="text-red-600 text-sm"
    >{{ error }}</span>
  </div>
</template>

<script setup lang="ts">
import { CurrencyDisplay, CurrencyInputOptions, useCurrencyInput } from 'vue-currency-input';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    name: string;
    // modelValue: string | number | null | undefined;
    error?: string;
    options?: CurrencyInputOptions;
  }>(),
  {
    error: undefined,
    /* eslint-disable no-null/no-null */
    // modelValue: null,
    options: () => ({
      currency: 'AUD',
      currencyDisplay: CurrencyDisplay.narrowSymbol,
      hideGroupingSeparatorOnFocus: false,
    }),
  },
);

// defineEmits(['update:modelValue']);

const inputValue = defineModel();
const { inputRef, setOptions, setValue } = useCurrencyInput(props.options);

const focusInput = () => {
  inputRef.value?.focus();
};

const blur = () => {
  inputRef.value?.blur();
};

defineExpose({
  focusInput,
  blur,
  setOptions,
  setValue,
});
</script>

<style lang="css" scoped></style>
